/* styles.css */


/* styles.css */

.navbar-content {
    max-height: 100%;
    overflow-y: auto;
    scrollbar-width: thin; /* Pour Firefox */
    scrollbar-color: #888 #f0f0f0; /* Couleur de la barre de défilement pour Firefox */

    /* Pour les navigateurs basés sur WebKit (Chrome, Safari) */
    &::-webkit-scrollbar {
        width: 6px; /* Largeur de la barre de défilement */
        display: none; /* Cacher la barre de défilement par défaut */
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888; /* Couleur du pouce de la barre de défilement */
    }

    &::-webkit-scrollbar-track {
        background-color: #f0f0f0; /* Couleur de la piste de la barre de défilement */
    }
}

